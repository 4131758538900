<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div class="d-flex justify-content-end">
        <!-- bouton voir point start -->
        <b-button
          v-if="$hasFonction(user, 'Voir compte')"
          class="ml-1"
          variant="primary"
          size="sm"
          @click="showReport('print')"
        >
          <b-icon-eye-fill></b-icon-eye-fill>
          VOIR LE POINT
        </b-button>
        <!-- bouton voir point end -->

        <!-- bouton export start -->
        <b-button
          v-if="$hasFonction(user, 'Voir compte')"
          variant="outline-primary"
          size="sm"
          class="ml-1"
          @click="ExportFomatExcel()"
        >
          <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
          Exporter
        </b-button>

        <b-button
          v-if="$hasFonction(user, 'Voir compte')"
          variant="outline-primary"
          size="sm"
          class="ml-2"
          @click="ExportAllToFomatExcel()"
        >
          <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
          Tout Exporter
        </b-button>
        <!-- bouton export end -->
      </div>

      <b-card no-body title="" class="mt-1">
        <div class="mt-2 mx-1 row justify-content-center">
          <!-- champ recherche start -->
          <b-form-group class="col-lg-2 col-md-6 mt-1 mt-lg-0">
            <b-form-input
              placeholder="Rechercher"
              type="text"
              class="d-inline-block"
              @input="filterRecherche"
            />
          </b-form-group>
          <!-- champ recherche end -->

          <!-- date start -->
          <div class="col-lg-2 col-md-6 mt-1 mt-lg-0">
            <b-form-input
              placeholder="Date de début"
              v-model="valueStart"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="filterValue()"
              />
              <label>Date de début</label>
            <span
              class="text-danger"
              style="font-size: 0.9em; margin-top: 5px; margin-left: 8px"
            >
              {{ errorValueStart }}
            </span>
          </div>

          <div class="col-lg-2 col-md-6 mt-1 mt-lg-0">
            <b-form-input
              placeholder="Date de fin"
              v-model="valueEnd"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="filterValue()"
              />
              <label>Date de fin</label>
          </div>
          <!-- date end -->

          <!-- select caisse start -->
          <b-form-select
            v-model="selectedCaisse"
            :options="associationsWithCaisse"
            value-field="text"
            class="col-lg-2 col-md-6 mt-1 mt-lg-0"
            @input="filterValue()"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                style="color: #b4b7bd; text-align: center"
                >-- Caisse concernée --</b-form-select-option
              >
            </template>
          </b-form-select>
          <!-- select caisse end -->
          <!-- solde start -->
          <div class="col-lg-2 col-md-6 mt-1 mt-lg-0">
            <b-input-group prepend="Solde">
              <b-form-input
                v-model="solde"
                type="text"
                disabled
                placeholder="Solde"
              >
              </b-form-input>
            </b-input-group>
          </div>
          <!-- solde end -->
        </div>

        <!-- table start -->
        <b-table
          v-model="excelJournalCaisse"
          id="my-table"
          responsive
          :fields="headers"
          :items="journalCaisses"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          :filter-function="filterFunction"
          @filtered="onFiltered"
          show-empty
          class="border-bottom mt-2"
        >
          <template #cell(entree)="data">
            <span>
              {{
                data.item.entree ? $thousandSeparator(data.item.entree) : "----"
              }}
            </span>
          </template>

          <template #cell(sortie)="data">
            <span>
              {{
                data.item.sortie ? $thousandSeparator(data.item.sortie) : "----"
              }}
            </span>
          </template>

          <template #cell(solde)="data">
            {{ data.item.solde ? $thousandSeparator(data.item.solde) : 0 }}
          </template>

                    <template #empty>
            <div class="text-center">
              <span class="text-nowrap">Aucune donnée existante</span>
            </div>
          </template>

          <template #emptyfiltered>
            <div class="text-center">
              <span class="text-nowrap"
                >L'élément recherché n'est pas dans la liste</span
              >
            </div>
          </template>
        </b-table>
        <!-- table end -->

        <div class="my-2 mx-2 row">
          <!-- per-page end -->
          <div class="col">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 70px"
            ></b-form-select>
            <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	              <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                </span>
            <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>

          </div>
          <!-- per-page end -->

          <!-- pagination start -->
          <b-pagination
            class="pagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <!-- pagination end -->
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ModelListSelect } from "vue-search-select";
// import { FormWizard, TabContent } from 'vue-form-wizard'
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupPrepend,
  BFormDatepicker,
  BIconArrowClockwise,
  BIconCircleFill,
  BIconCheckCircleFill,
  BIconEyeFill,
  BFormDatalist,
  BIconCartPlus,
  VBTooltip,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";

export default {
  components: {
    ModelListSelect,
    Cleave,
    FormWizard,
    TabContent,
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BInputGroupPrepend,
    BPagination,
    BFormSelect,
    BFormDatalist,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BIconCartPlus,
    BIconEyeFill,
    BFormDatepicker,
    BIconCircleFill,
    BIconArrowClockwise,
    BIconCheckCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      dateFormatOptions: { day: "numeric", month: "numeric", year: "numeric" },
      associationsWithCaisse: [],
      valueStart: null,
      valueEnd: null,
      errorValueStart: "",
      entete: "",
      etatButtonOperation: true,
      etatButtonTransfert: true,
      etatButtonRetrait: true,
      etatButtonReglement: true,
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      selectedCaisse: null,
      perPage: 10,
      currentPage: 1,
      filter: "",
      filterVal: null,
      pageOptions: [],
      journalCaisses: [],
      journalCaissesList: [],
      selectedAssociation: "",
      allRows: 0,
      erreurs: {},
      headers: [
        {
          key: "created_at",
          label: "Date de l'opération",
          sortable: true,
          // class: 'text-center'
        },
        {
          key: "libelle",
          label: "Libellé",
          sortable: true,
        },
        {
          key: "caisse",
          label: "Caisse concernée",
          sortable: true,
        },
        {
          key: "entree",
          label: "Entrée",
          sortable: true,
        },
        {
          key: "sortie",
          label: "Sortie",
          sortable: true,
        },
        {
          key: "solde",
          label: "Solde",
          sortable: true,
        },
      ],
      excelJournalCaisse: null,
      numberFormat: {
        numeral: true,
        delimiter: " ",
        numeralThousandsGroupStyle: "thousand",
      },

      dialogTransfert: false,
      dialogReglement: false,
      regementTypes: ["Association", "Importateur"],
      reglementCaisseList: [],
      dialogOperation: false,
      dialogRetrait: false,
      dialogPoint: false,

      disableCaisse: false,
      devPrincipale: {},
      comptes: [],
      accountsList: [],
      comptesReglement: [],
      comptesFsr: [],
      AssocieComptes: [],
      AssociesAndImporter: [],
      AssociationAssocies: [],
      AssocieListOfAssociations: [],
      AssociationComptesAssocies: [],
      operationsBackup: [],
      societes: [],
      associes: [],
      accountsListCP: [],
      importateurs: [],
      associations: [],
      concernedAccounts: [],

      disableCP: true,
      isImportateur: false,
      message: "",
      concernedAccountDevise: "",

      showExtra: false,
      soldeActivite: 0,
      soldePret: 0,
      isOperating: false,
      existSociete: false,

      transfertForm: {},
      operationForm: {},
      retraitForm: {},
      reglementForm: {},

      //Comptes tiers
      compte_tiers: true,
      operation_compte_tiers: true,
      message: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    taux() {
      let formateBalance = 1;
      if (
        this.transfertForm.conversion_montant &&
        this.transfertForm.operation_montant
      ) {
        (formateBalance = this.transfertForm.conversion_montant),
          this.options / this.transfertForm.operation_montant,
          this.options;
      }
      return formateBalance ? formateBalance : 1;
    },

    //COMPOSITION DU LIBELLE POUR REGLEMENT
    checkReglementLib() {
      let ReglementPour = null;
      let ReglementMontant = "";
      let ReglementPar = null;

      if (this.reglementForm.caisse_ass_imp) {
        ReglementPar = this.reglementCaisseList.find(
          ({ id }) => id === this.reglementForm.caisse_ass_imp
        );
        ReglementPar = "par " + ReglementPar.name + " ";
      } else {
        ReglementPar = "";
      }

      if (this.reglementForm.compte_concerne) {
        ReglementPour = this.comptesReglement.find(
          ({ id }) => id === this.reglementForm.compte_concerne
        );
        ReglementPour = "pour " + ReglementPour.libelle + " ";
      } else {
        ReglementPour = "";
      }

      if (this.reglementForm.amount) {
        ReglementMontant = "de " + this.reglementForm.amount + " ";
      } else {
        ReglementMontant = "";
      }
      return (this.reglementForm.libelle =
        "Reglement " + ReglementPour + ReglementMontant + ReglementPar + ".");
    },

    //VERIFIER SI UNE DEVISE EST LA DEVISE PRINCIPALE
    isPrincipaleDevise() {
      if (
        this.devPrincipale &&
        this.devPrincipale.name === this.concernedAccountDevise
      ) {
        this.transfertForm.conversion_montant =
          this.transfertForm.operation_montant;
        return true;
      }
      return false;
    },

    
    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },

    // calculerSolde() {
    //   let entree = 0;
    //   let sortie = 0;
    //   this.journalCaisses.forEach((element) => {
    //     if (element.entree) {
    //       entree += element.entree;
    //     }

    //     if (element.sortie) {
    //       sortie += element.sortie;
    //     }
    //   });

    //   return entree - sortie;
    // },

    //CALCUL DU SOLDE DU COMPTE DANS LES FILRES
    soldeCompte() {
      let entree = 0;
      let sortie = 0;
      let solde = 0;
      let journalCaisses = this.journalCaisses;

      if (this.caisseFilterValue) {
        journalCaisses = journalCaisses.filter(
          (operation) => operation.caisse == this.caisseFilterValue
        );
      }

      /*
        - Déterminer le premier solde  : il s'agit du montant de la premiere opération (entree ou sortie)
        -
      */
      if (journalCaisses.length > 0) {
        // let lastItem = journalCaisses[journalCaisses.length - 1];
        // lastItem.solde = lastItem.entree ? lastItem.entree : lastItem.sortie ;

        journalCaisses
          .slice()
          .reverse()
          .forEach((element, index) => {
            if (index == 0) {
              element.solde = element.entree ? element.entree : -element.sortie;
            } else if (index > 0) {
              if (element.entree) {
                element.solde =
                  journalCaisses[journalCaisses.length - index].solde +
                  element.entree;
                // element.solde = journalCaisses[journalCaisses.length - index-1].solde - element.sortie ;
              } else if (element.sortie) {
                element.solde =
                  journalCaisses[journalCaisses.length - index].solde -
                  element.sortie;
                // element.solde = journalCaisses[index - 1].solde - element.sortie
              } else {
                element.solde =
                  journalCaisses[journalCaisses.length - index].solde + 0;
              }
            }
          });
      }

      journalCaisses.forEach((element, index) => {
        if (element.entree) {
          entree += element.entree;
        }

        if (element.sortie) {
          sortie += element.sortie;
        }
      });
      this.soldeActivite = entree - sortie + this.soldePret;
      return this.$thousandSeparator(entree - sortie);
    },

    solde() {
      var solde = 0;
      var journalCaissesAfterSelectedCaisse = [];

      if (this.selectedCaisse) {
        journalCaissesAfterSelectedCaisse = this.journalCaissesList.filter(
          (journalCaisse) => journalCaisse.caisse == this.selectedCaisse
        );
      } else {
        journalCaissesAfterSelectedCaisse = this.journalCaissesList;
        //          this.journalCaisses.forEach((element) => {
        //   if (element.entree) {
        //     solde += element.entree;
        //   }

        //   if (element.sortie) {
        //     solde -= element.sortie;
        //   }
        // });
      }

      journalCaissesAfterSelectedCaisse.reverse().forEach((element, index) => {
        if (index == 0) {
          if (element.entree && element.sortie) {
            element.solde = element.entree;
          } else if (element.entree && element.sortie) {
            element.solde = -element.sortie;
          } else {
            element.solde = element.entree - element.sortie;
          }
        } else {
          if (element.entree && element.sortie) {
            element.solde =
              journalCaissesAfterSelectedCaisse[index - 1].solde +
              element.entree;
          } else if (element.entree && element.sortie) {
            element.solde =
              journalCaissesAfterSelectedCaisse[index - 1].solde -
              element.sortie;
          } else {
            element.solde =
              journalCaissesAfterSelectedCaisse[index - 1].solde +
              element.entree -
              element.sortie;
          }
        }
      });

      journalCaissesAfterSelectedCaisse.forEach((element) => {
        if (element.entree) {
          solde += element.entree;
        }

        if (element.sortie) {
          solde -= element.sortie;
        }
      });

      this.journalCaisses = journalCaissesAfterSelectedCaisse.reverse();

      return this.$thousandSeparator(solde);
    },

    //   else {
    //     this.journalCaisses = this.journalCaissesList;

    //     this.journalCaisses.forEach((element) => {
    //       if (element.entree) {
    //         solde += element.entree;
    //       }

    //       if (element.sortie) {
    //         solde -= element.sortie;
    //       }
    //     });

    //     return this.$thousandSeparator(solde);
    //   }
    // },
  },
  created() {
    this.getJournalCaisse();
    this.getAssociations();
  },
  methods: {
    filterValue(val) {
      this.filter = " hh";
    },
    dateFilter(start, end, value) {
      this.filter = " ";
      if (!start && !end) {
        return true;
      } else if (start && !end) {
        return value >= start;
      } else if (!start && end) {
        return value <= end;
      }
      return value >= start && value <= end;
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
        totalRows()
      },



    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
    },
    filterRecherche(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.filterVal = val;
    },
    filterFunction(row, DateFilter) {
      var start = null;
      var end = null;
      var value = new Date(this.toDate(row.created_at));

      if (this.valueStart) {
        var parts = this.valueStart.split("-");
        start = new Date(parts[0], parts[1] - 1, parts[2]);
      }

      if (this.valueEnd) {
        var parts = this.valueEnd.split("-");
        end = new Date(parts[0], parts[1] - 1, parts[2]);
      }

      if (
        this.filterVal != null &&
        row.libelle.toLowerCase().indexOf(this.filterVal.toLowerCase()) == -1 &&
        row.caisse.toLowerCase().indexOf(this.filterVal.toLowerCase()) == -1 &&
        this.selectedCaisse == null
      ) {
        return false;
      }

      if (this.selectedCaisse != null && this.selectedCaisse != row.caisse) {
        return false;
      } else {
        return this.dateFilter(start, end, value);
      }
    },

    openReglement() {
      this.dialogReglement = true;
      let account = this.accountsListCP.find(
        ({ libelle }) => libelle === "Coffre-fort"
      );
      this.reglementForm.compteCP = account.id;
      this.reglementForm.type_caisse = "Association";
      this.reglementCaisseList = this.associations;
    },

    getJournalCaisse() {
      this.$http
        .get("/archive/caisses")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }

          this.journalCaisses = res.data.data;
          this.journalCaissesList = res.data.data;

          if (this.journalCaissesList.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }


          this.allRows = this.journalCaisses.length

        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    /**
     * Fonction pour lancer le filtre
     * lorsqu'on a deux date
     */
    twoDateFiltre(arrayData, data1, data2) {
      var filtreJournalCaissesList = [];

      arrayData.forEach((element) => {
        var parts = element.created_at.split("-");
        var annee = parts[2];
        var mois = parts[1];
        var jour = parts[0];

        var date = annee + "-" + mois + "-" + jour;
        date.toString();

        if (
          Date.parse(date) >= Date.parse(data1) &&
          Date.parse(date) <= Date.parse(data2)
        ) {
          filtreJournalCaissesList.push(element);
        }
      });

      return filtreJournalCaissesList;
    },

    /**
     * Fonction pour lancer le filtre
     * lorsqu'on a seulement la date début
     */
    oneDateFiltre(arrayData, data) {
      var filtreJournalCaissesList = [];

      arrayData.forEach((element) => {
        var parts = element.created_at.split("-");
        var annee = parts[2];
        var mois = parts[1];
        var jour = parts[0];

        var date = annee + "-" + mois + "-" + jour;
        date.toString();
        if (Date.parse(date) >= Date.parse(data)) {
          filtreJournalCaissesList.push(element);
        }
      });

      return filtreJournalCaissesList;
    },

    /**
     * Fonction pour lancer le filtre sur
     * une caisse sélectionné
     */
    caisseFiltre(arrayData, data) {
      var filtreJournalCaissesList = [];

      arrayData.forEach((element) => {
        if (element.caisse == data) {
          filtreJournalCaissesList.push(element);
        }
      });

      return filtreJournalCaissesList;
    },

    dateFiltre() {
      if (this.errorValueStart) {
        this.errorValueStart = "";
      }

      if (this.valueStart) {
        var dateFiltre = {
          date_start: this.valueStart,
          date_end: this.valueEnd,
          caisse: "",
        };

        if (
          this.selectedCaisse &&
          this.selectedCaisse != "-- Caisse concernée --"
        ) {
          dateFiltre.caisse = this.selectedCaisse;
        }

        if (dateFiltre.date_end) {
          var twoDateFiltre = this.twoDateFiltre(
            this.journalCaissesList,
            dateFiltre.date_start,
            dateFiltre.date_end
          );

          if (dateFiltre.caisse) {
            this.journalCaisses = this.caisseFiltre(
              twoDateFiltre,
              dateFiltre.caisse
            );
          } else {
            this.journalCaisses = twoDateFiltre;
          }
        } else {
          var onDateFiltre = this.oneDateFiltre(
            this.journalCaissesList,
            dateFiltre.date_start
          );

          if (dateFiltre.caisse) {
            this.journalCaisses = this.caisseFiltre(
              onDateFiltre,
              dateFiltre.caisse
            );
          } else {
            this.journalCaisses = onDateFiltre;
          }
        }
      } else if (this.valueEnd) {
        this.journalCaisses = this.journalCaissesList;
        this.errorValueStart = "Choisissez une date de début !";
      } else {
        if (this.errorValueStart) {
          this.errorValueStart = "";
        }

        if (
          this.selectedCaisse &&
          this.selectedCaisse != "-- Caisse concernée --"
        ) {
          this.journalCaisseFiltre();
        } else {
          this.journalCaisses = this.journalCaissesList;
        }
      }
    },

    journalCaisseFiltre() {
      if (
        this.selectedCaisse &&
        this.selectedCaisse != "-- Caisse concernée --"
      ) {
        var journalCaisseFiltre = {
          caisse: this.selectedCaisse,
          date_start: "",
          date_end: "",
        };

        if (this.valueStart) {
          journalCaisseFiltre.date_start = this.valueStart;
        }

        if (this.valueEnd) {
          journalCaisseFiltre.date_end = this.valueEnd;
        }

        if (journalCaisseFiltre.caisse) {
          var caisseFiltre = this.caisseFiltre(
            this.journalCaissesList,
            journalCaisseFiltre.caisse
          );

          if (journalCaisseFiltre.date_start) {
            var dateFiltre = [];

            if (journalCaisseFiltre.date_end) {
              dateFiltre = this.twoDateFiltre(
                caisseFiltre,
                journalCaisseFiltre.date_start,
                journalCaisseFiltre.date_end
              );
            } else {
              dateFiltre = this.oneDateFiltre(
                caisseFiltre,
                journalCaisseFiltre.date_start
              );
            }

            this.journalCaisses = dateFiltre;
          } else {
            this.journalCaisses = caisseFiltre;
          }
        }
      } else {
        if (this.valueStart) {
          this.dateFiltre();
        } else {
          this.journalCaisses = this.journalCaissesList;
        }
      }
    },

    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
    },

    showReport(action) {
      const filters = {
        compte: (compte) =>
          this.comptesFilterValue
            ? compte.libelle === this.comptesFilterValue
            : true,
        caisse: (caisse) =>
          this.selectedCaisse ? caisse === this.selectedCaisse : true,
        created_at: (created_at) => {
          let start = this.convert(this.valueStart);
          let end = this.convert(this.valueEnd);

          created_at = this.toDate(created_at);

          if (!start && !end) {
            return true;
          } else if (start && !end) {
            return created_at >= start;
          } else if (!start && end) {
            return created_at <= end;
          }
          created_at = new Date(created_at);
          start = new Date(start);
          end = new Date(end);
          return created_at >= start && created_at <= end;
        },
      };

      let result = this.globalFilter(this.journalCaisses, filters);

      if (action == "print") {
        this.print(result);
      } else {
        return result;
      }
    },

    print(data) {
      let toPrint = {
        type: "Caisse",
        concerne: this.selectedCaisse ? this.selectedCaisse : "Caisse globale",
        headers: this.headers,
        showSoldeColumn: true,
        cahier: data,
        periodeDu: this.convert(this.valueStart),
        periodeAu: this.convert(this.valueEnd),
        solde: this.$thousandSeparator(this.soldeCompte),
        soldeDebut: this.$thousandSeparator(this.soldeDebut(data)),
      };

      this.$router.push({
        name: "operation-print",
        params: { data: toPrint },
      });
    },

    globalFilter(array, filters) {
      if (!array) array = [];
      array.forEach((element) => {
        element["created_at"] = this.convertg(element["created_at"]);
      });
      const filterKeys = Object.keys(filters);
      return array.filter((item) => {
        // je valide tous les critères de tri
        return filterKeys.every((key) => {
          // ignorer les predicats qui ne sont pas des fonctions
          if (typeof filters[key] !== "function") return true;
          return filters[key](item[key]);
        });
      });
    },

    convertg(date) {
      if (date) {
        date = date.replace(/\//g, "-");
      }
      return date;
    },

    convert(date) {
      if (date) {
        date = new Date(date);
      }
      return this.reformatDate(date);
    },

    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        let mois = date.getMonth() + 1;
        let jour = date.getDate();
        date = annee + "-" + mois + "-" + jour;
        return date.toString();
      }
      return Date.parse(date);
    },

    soldeDebut(data) {
      let start = this.convert(this.valueStart);
      if (!start) return 0;
      else {
        if (data[data.length]) {
          return data[data.length].solde;
        }
        return 0;
      }
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.excelJournalCaisse.forEach((element) => {
        obj = {
          "DATE OPERATION(S)": element.created_at,
          "LIBELLE(S)": element.libelle,
          "CAISSE CONCERNÉE(S)": element.caisse,
          "ENTRÉE(S)": element.entree ? element.entree : 0,
          "SORTIE(S)": element.sortie ? element.sortie : 0,
          "SOLDE(S)": element.solde ? element.solde : 0,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste journal caisse",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },


    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.journalCaisses.forEach((element) => {
        obj = {
          "DATE OPERATION(S)": element.created_at,
          "LIBELLE(S)": element.libelle,
          "CAISSE CONCERNÉE(S)": element.caisse,
          "ENTRÉE(S)": element.entree ? element.entree : 0,
          "SORTIE(S)": element.sortie ? element.sortie : 0,
          "SOLDE(S)": element.solde ? element.solde : 0,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste journal caisse",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    getAssociations() {
      this.$http.get("/associations").then((response) => {
        var obj = {};

        this.associations = response.data.data;
        this.associationsWithCaisse = [];
        this.associations.forEach((element) => {
          element.text = element.name;
        });
        this.associationsWithCaisse.push({
          value: "caisse",
          text: "Caisse",
          name: "Caisse",
        });
        this.associations.forEach((element) => {
          if (element.name) {
            obj = {
              value: element.id,
              text: element.name,
              name: element.name,
            };
            this.associationsWithCaisse.push(obj);
          }
        });
      });
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}

.vue-form-wizard {
  box-shadow: 0px 0px;
  margin-bottom: 0px !important;
}

.wizard-card-footer {
  height: 20px;
}

.wizard-footer-left .wizard-btn {
  /* color: #27295c !important; */
  /* border: 1px solid #27295c !important; */
  /* background-color: white !important; */
  padding-left: 1px !important;
  padding-top: 1px !important;
  padding-right: 15px !important;
  padding-bottom: 1px !important;
}

.wizard-footer-right .wizard-btn {
  color: #27295c !important;
  border: 1px solid #27295c !important;
  background-color: white !important;
  padding-left: 15px !important;
  padding-top: 1px !important;
  padding-right: 0px !important;
  padding-bottom: 1px !important;
}

.wizard-card-footer {
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .annuler {
    margin-bottom: 5px;
  }

  .blockSearch {
    width: 350px !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 800px) {
  /* .annuler {
			margin-bottom: 5px;
		} */

  .form {
    margin-left: 10%;
  }

  .formRow .form-group {
    width: 500px;
  }

  .blockForm {
    margin-left: 0px !important;
  }

  .blockSearch {
    width: 80% !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }

  .BlockbButton {
    padding-right: 0px !important;
    margin-right: 40px !important;
  }
}

@media screen and (max-width: 1000px) {
  #solde-input {
    margin-left: 20px !important;
  }

  .form {
    margin-left: 12%;
  }

  .fonction {
    width: 520px;
  }

  .BlockbButton {
    padding-right: 130px !important;
  }
}
</style>
